@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body{
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}
html{
  font-size: 65.2%;
}
@media screen and (max-width: 806px){
  html{
    font-size: 54.8%;
  }
}
@media screen and (max-width: 405px){
  html{
    font-size: 49.8%;
  }
}

@media screen and (min-width: 1600px){
  html{
    font-size:80.6%;
  }
}

img{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
